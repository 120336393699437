import React, { Component } from "react";
// import logo from "../logo.svg";
import logo from "../Rajeev_Rasa_logo.png";
import { Link, animateScroll as scroll } from "react-scroll";

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = "https://drive.google.com/file/d/1wafl_I5Cd53Y_O5-ScOhl8KwLbSyVaR8/view?usp=sharing";
  link.download = "Rajeev_Rasa_resume.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <img
            src={logo}
            className="nav-logo"
            alt="Logo"
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Career
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Projects
              </Link>
            </li>
           <li className="nav-item">
              <Link
                activeClass="active"
                onClick={handleDownload}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}  
              >
                Resume
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Connect
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
import React from "react";
// import {SocialMediaIconsReact} from 'social-media-icons-react';
import { AnimatedSocialIcon } from 'react-animated-social-icons';
import { Container } from "react-bootstrap";

export default function Social({ id }) {
    return (
        <div className="section-content" id={id}>
            {/* <SocialMediaIconsReact icon="twitter" url="https://twitter.com/alwaysRajeev"/> */}
            <Container className="social"> 
            <AnimatedSocialIcon
                brandName="linkedin"
                url="https://www.linkedin.com/in/rajeevrasa/"
                animation="bounce"
                defaultColor="black"
                hoverColor="#2867B2"
                width="2em"
                animationDuration={0.8}
                style={{ padding: '2em' }}
            />
            <AnimatedSocialIcon
                brandName="twitter"
                url="https://twitter.com/alwaysRajeev"
                animation="bounce"
                defaultColor="black"
                hoverColor="skyblue"
                width="2em"
                animationDuration={0.8}
                style={{ padding: '2em' }}
            />
            <AnimatedSocialIcon
                brandName="github"
                url="https://github.com/obiwankenoby"
                animation="bounce"
                defaultColor="black"
                hoverColor="grey"
                width="2em"
                animationDuration={0.8}
                style={{ padding: '2em' }}
            />
            <AnimatedSocialIcon
                brandName="youtube"
                url="https://www.youtube.com/channel/UCeRwEIFq5IdYWFZubaUS9wg"
                animation="bounce"
                defaultColor="black"
                hoverColor="red"
                width="2em"
                animationDuration={0.8}
                style={{ padding: '2em' }}
            />
            </Container>
        </div>
    );
}
  
import React, { Component } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Home";
import Career from "./Career";
import Projects from "./Projects";
import Social from "./Social";
class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Home
          title="About"
          id="section1"
        />
        <Career
          title="Career"
          dark={false}
          id="section2"
        />
        <Projects
          title="Projects"
          dark={false}
          id="section3"
        />
        <Social
          title="Connect"
          dark={false}
          id="section5"
        />
      </div>
    );
  }
}

export default App;
import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Card, CardHeader, CardBody } from 'react-simple-card';

export default function Projects({ id }) {

    return (
        <div className="section-content" id={id}>
            <h1>ACADEMIC PROJECTS</h1>
            <Container>
                <Row style={{padding:'2rem'}}>
                    <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '30rem' }}>
                            <CardHeader className="projectCards">
                                TEXT CLASSFICATION OF ARTICLES USING NAIVE BAYES
                            </CardHeader>
                            <CardBody>
                                <p className="projectText">
                                    900 text articles from 3 scientific domains were preprocessed to train the naive Bayes classifier. The trained classifier classified the articles of test data with an accuracy of 93.42%. Developed this classifier using various packages like Sys, NumPy, Math from scratch in python. <i>(Data Mining, 2018)</i>
                                </p>
                            </CardBody>

                        </Card>
                    </Col>
                    <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '30rem' }}>
                            {/* <Body>This is some text within a card body.</Body> */}
                            <CardHeader className="projectCards">
                                PHOTO BUCKET
                            </CardHeader>
                            <CardBody>
                                <p className="projectText">
                                    Developed a script in Python to perform CRUD operations on pictures in different cloud platforms like AWS, Microsoft Azure and IBM Bluemix using their respective data storage technologies. <i>(Advanced Database Systems, 2017)</i>
                                </p>
                            </CardBody>

                        </Card>
                    </Col>
                    <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '30rem' }}>
                            <CardHeader className="projectCards">
                                GYM MANAGEMENT SYSTEM
                            </CardHeader>
                            <CardBody className="projectText">
                                Built this CMS which simulates a gym customer management system. Developed frontend using Bootstrap, HTML, CSS, JavaScript. Designed and built database schema useing MySQL database and managed the same using phpMyAdmin. Apache Tomcat was used as a server to deploy and run the web application. <i>(Database Systems, 2017)</i>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '30rem' }}>
                            <CardHeader className="projectCards">
                                BOOK MY HALL APP
                            </CardHeader>
                            <CardBody className="projectText">
                                Developed an Android application which facilitates hall reservation at the university by creating an interface between students / faculty and conference & banquet halls. Used Firebase as backend for storing user, booking information and other demographics of halls. <i>(Software Engineering I, 2017)</i>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '30rem' }}>
                            <CardHeader className="projectCards">
                                CLIENT SERVER CHAT SYSTEM
                            </CardHeader>
                            <CardBody className="projectText">
                                Developed a client sever socket programming application in java where clients can register to a server and request to start a chat with any one of the online clients. Clients can login, chat, log off and check for other online clients. <i>(Distributed Systems, 2016)</i>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col>
                        <Card className="cardClass1" style={{ width: '20rem', height: '25rem' }}>
                            <CardHeader className="projectCards">
                                MULTI-PLAYER SNAKES AND LADDERS LAN GAME
                            </CardHeader>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
            </Container>
            {/* </Card> */}
            {/* </Card> */}
            {/* </div> */}

            {/* <ReactSpeedometer /> */}

        </div>
    );
}
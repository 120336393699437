import React from "react";
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./gradCap.svg";


import timelineElements  from "./timeLineElements";

import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

export default function Career({id}) {
    // e47b52 06D6A0
    let workIconStyles = { background: "#e47b52"}
    let schoolIconStyles = { background: "#f9c74f" };
    return (
        <div className="section-content" id={id}>
             {/* <h1>Career</h1> */}
        <div className="timeline-content" id={id}>
          <VerticalTimeline className="verticalTimeline">
              {
                timelineElements.map( element => {
                    let isWorkIcon = element.icon === "work"
                    return (
                        <VerticalTimelineElement
                            key={element.key}
                            date={element.date}
                            dateClassName="date"
                            iconStyle = {isWorkIcon ? workIconStyles : schoolIconStyles}
                            icon = {isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                        >
                            <h3 className="vertical-timeline-element-title">
                                {element.title}
                            </h3>
                            <h5 className="vertical-timeline-element-subtitle">
                                {element.location}
                            </h5>
                            {/* <p id="description">
                                {element.description}
                            </p> */}
                        </VerticalTimelineElement>
                    )
                })
              }
          </VerticalTimeline>
        </div>
        </div>
    );
  }
let timelineElements = [
  {
    id: 1,
    title: "Senior Software Engineer",
    location: "New Relic",
    description:
      "",
    buttonText: "",
    date: "February, 2024 - Present",
    icon: "work",
  },
  {
    id: 2,
    title: "Software Development Engineer",
    location: "Amazon Web Services",
    description:
      "Converting data to a graphical interface, through the use of HTML, CSS, and JavaScript, so that users can view and interact with that data.",
    buttonText: "View Frontend Projects",
    date: "October, 2020 - November, 2022",
    icon: "work",
  },
  {
    id: 3,
    title: "Software Developer",
    location: "Citi",
    description:
      "Working hand-in-hand with front-end developers by providing the outward facing web application elements server-side logic. Creating the logic to make the web app function properly, and accomplishing this through the use of server-side scripting languages.",
    buttonText: "View Backend Projects",
    date: "August, 2019 - October, 2020",
    icon: "work",
  },
  {
    id: 4,
    title: "Software Developer",
    location: "Verizon",
    description:
      "Assessing the quality of specifications and technical design documents in order to ensure timely, relevant and meaningful feedback.",
    buttonText: "Company Website",
    date: "October, 2018 - August, 2019",
    icon: "work",
  },
  {
    id: 5,
    title: "Master of Science in Computer Science",
    location: "The University of Texas at Arlington",
    description:
      "",
    buttonText: "Course Certificate",
    date: "May, 2018",
    icon: "school",
  },
  {
    id: 6,
    title: "Research Intern",
    location: "The University of Texas at Arlington Research Institute",
    description:
      "",
    buttonText: "College Projects",
    date: "January, 2017 - August, 2017",
    icon: "work",
  },
  {
    id: 7,
    title: "Senior Software Engineer",
    location: "Infosys Limited",
    description:
      "",
    date: "February, 2014 - June, 2016",
    icon: "work",
  },
  {
    id: 8,
    title: "Bachelor of Technology in Computer Science and Engineering",
    location: "Jawaharlal Nehru Technological University Hyderabad",
    description:
      "",
    date: "May, 2013",
    icon: "school",
  },
];

export default timelineElements;
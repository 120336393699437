import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-circular-progressbar/dist/styles.css';
// import SkillBar from 'react-skillbars';
// import { AnimatedSocialIcon } from 'react-animated-social-icons';

import {CardBody, CardHeader } from 'react-simple-card';
import css from '../src/Assets/css3.png';
import html from '../src/Assets/html5.png';
import bootstrap from '../src/Assets/bootstrap.png';
import hbase from '../src/Assets/hbase.png';
import mysql from '../src/Assets/mysql.png';
import oracle from '../src/Assets/oracle.png';
import dynamo from '../src/Assets/dynamo.png';
import kotlin from '../src/Assets/kotlin.png';
import kafka from '../src/Assets/kafka-logo.png';
import jUnit from '../src/Assets/JUnit_5.png';
import jQuery from '../src/Assets/JQuery.png';

export default function Home({ id }) {
/*
  const skills = [
    { type: "Java", level: 90 },
    { type: "Javascript", level: 70 },
    { type: "C#", level: 50 },
    { type: "Perl", level: 30 },
    { type: "React", level: 30 },
  ];
  const dbSkills = [
    { type: "MySQL", level: 85 },
    { type: "Orcale", level: 85 },
    { type: "HBase", level: 50 },
    { type: "Firebase", level: 40 },
  ];
  const webSkills = [
    { type: "REST", level: 85 },
    { type: "GraphQL", level: 70 },
    { type: "SOAP", level: 70 },
    { type: "Tomcat", level: 80 },
  ];
  const frameWorks = [
    { type: "Spring Boot", level: 70 },
    { type: "Kafka", level: 65 },
    { type: "JUnit", level: 80 },
    { type: "Mockito", level: 60 },
  ];
  const colors = {
    "bar": "#3498db",
    "title": {
      "text": "#fff",
      "background": "#2980b9"
    }
  }
  */
 
  return (
    <div className="section-content" id={id}>
      {/* <h1>About</h1> */}
      <div className="about-content">
        <p>
          Welcome! I am Rajeev Rasa.
          I am a Senior Software Development Engineer with over 7 years of work experience and a Master's degree in Computer Science from The University of Texas at Arlington. I like to build things both as part of my profession and also as a hobby.
          I have extensive experience in design, development and implementation of highly responsive web application interfaces, web user interfaces and data heavy solutions. I always look forward to throwing myself at new challenges and scale new heights by solving them.
          {/* My motto is to never stop learning and never take a step back.  */}
        </p>
      </div>
      {/* <h3 className="h3Class"> SKILLS </h3> */}
      {/* <Card className="cardClass" style={{ width: '80%' }}> */}
      <Container>
        <Row>
          <Col>
            {/* <Card className="cardClass2" style={{ width: '100%' }}> */}
            <CardHeader> LANGUAGES </CardHeader>
            <CardBody>
            <img src="https://img.icons8.com/fluency/48/null/java-coffee-cup-logo.png" alt="java"/>
            <img width={44} height={44} src={kotlin} alt="kotlin"/>
            <img src="https://img.icons8.com/dusk/48/null/javascript-logo.png" alt="js"/>
            <img src="https://img.icons8.com/ios/48/null/c-sharp-logo.png" alt="csharp"/>
            <img src="https://img.icons8.com/color/48/null/perl.png" alt="perl"/>
            <img src="https://img.icons8.com/color/48/null/react-native.png" alt="react"/>
            {/* <AnimatedSocialIcon
                brandName="youtube"
                url="https://www.youtube.com/channel/UCeRwEIFq5IdYWFZubaUS9wg"
                animation="bounce"
                defaultColor="black"
                hoverColor="red"
                width="4em"
                animationDuration={0.8}
                style={{ padding: '2em' }}
            /> */}
              {/* <SkillBar skills={skills} colors={colors} height={22} animationDelay={100} /> */}
            </CardBody>
            {/* </Card> */}
          </Col>
          <Col>
            {/* <Card className="cardClass2" style={{ width: '100%', height:'300px' }}> */}
            <CardHeader> DATABASES </CardHeader>
            <CardBody>
            <img width={48} height={48} src={mysql} alt="mysql"/>
            <img width={48} height={48} src={oracle} alt="oracle"/>
            <img width={48} height={48} src={dynamo} alt="dynamo"/>
            <img src="https://img.icons8.com/color/48/null/firebase.png"  alt="fbase"/>
            <img width={48} height={48} src={hbase} alt="hbase"/>
            </CardBody>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardHeader> WEB TECHNOLOGIES </CardHeader>
            <CardBody>
            <img width={48} height={48} src={css} alt="css"/>
            <img width={48} height={48} src={html} alt="html"/>
            <img width={48} height={48} src={bootstrap} alt="bootstrap"/>
            <img src="https://img.icons8.com/color/48/null/tomcat.png" alt="tcat"/>
            <img src="https://img.icons8.com/color/48/null/graphql.png" alt="graphql"/>
            <img src="https://img.icons8.com/ios/48/null/api-settings.png" alt="rest"/>
            </CardBody>
          </Col>
          <Col>
            <CardHeader> FRAMEWORKS </CardHeader>
            <CardBody>
              <img src="https://img.icons8.com/color/48/null/spring-logo.png" alt="spring"/>
              <img width={30} height={48} src={kafka} alt="kafka"/>
              <img width={48} height={30} src={jUnit} alt="jUnit"/>
              <img width={48} height={48} src={jQuery} alt="jQuery"/>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
}